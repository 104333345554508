<template>
  <div>
    <!-- allDetail -->
    <skeleton v-if="!goodsInfo" type="detail" />
    <div v-else>
      <div v-if="goodsStatus" class="goods-detail">
        <!-- banner图片 -->
        <van-swipe :autoplay="3000" indicator-color="#ED2E2E">
          <van-swipe-item
            v-for="(image, index) in goodsInfo.banner"
            :key="index"
          >
            <img v-lazy="image" class="banner" />
          </van-swipe-item>
        </van-swipe>
        <!-- 商品价格，商品名称，商品描述 -->
        <goodsInfo
          :flash-info="{}"
          :goods-status="goodsStatus"
          :goods-info="goodsInfo"
        />
        <div class="crowd-ratio">
          <van-progress
            style="width: 90%; display: inline-block"
            color="#FF6565"
            :percentage="skuInfo.crowInfo.ratio.replace('%', '')"
            stroke-width="6"
            :show-pivot="false"
          />
          <span>{{ skuInfo.crowInfo.ratio }}</span>
        </div>
        <div class="crowd-info">
          <div class="crowd-info-item">
            <p style="color: #ff6565">{{ skuInfo.crowInfo.orderNum }}</p>
            <p>成交数</p>
          </div>
          <div class="crowd-info-item">
            <p>{{ skuInfo.crowInfo.orderPrice }}</p>
            <p>已筹金额</p>
          </div>
          <div class="crowd-info-item">
            <p>{{ skuInfo.crowInfo.proMoney }}</p>
            <p>目标金额</p>
          </div>
          <div class="crowd-info-item">
            <p>{{ skuInfo.crowInfo.date }}</p>
            <p>剩余时间</p>
          </div>
        </div>

        <div class="crowd-tip">
          <div class="crowd-line"></div>
          <div class="crowd-text">
            <span>风险提示：</span><span>{{skuInfo.crowInfo.content}}</span>
          </div>
        </div>
        <div class="line" />
        <!-- 商品评论 -->
        <van-submit-bar   button-text="立即购买" @submit="createOrderByProduct" />
        <div v-if="comment.result && comment.result.length > 0" class="line" />
        <!--5 推荐-->
        <groupDeRecommend :mend-goods="mendGoods" />
        <!-- 商品详情 -->
        <div class="goods-detail-info" style="width: 100%">
          <div class="tit">商品详情</div>
          <div style="width: 100%" v-html="goodContent" />
        </div>

      </div>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { ActionSheet, Icon, Lazyload, Swipe, SwipeItem, Progress ,SubmitBar} from "vant";
import { isWeChat } from '@/utils/common/validate.js'
Vue.use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Icon)
  .use(ActionSheet)
  .use(Progress).use(SubmitBar);
import groupDeRecommend from "@/component/GoodsDetail/groupDeRecommend";
import {
  productInfo,
  productContent,
  productComment,
  getGoodsDetail,
  skuChangeInfo,
} from "@/services/goods.js";
import {
  getCodeByOpenid,
} from '@/services/userApi'
import OpenApp from "@/component/GoodsDetail/goodsTopBtm";
import goodsInfo from "@/component/GoodsDetail/goodsInfo";
import goodsHandle from "@/component/GoodsDetail/goodsHandle";
import AssessList from "@/component/GoodsDetail/assessList";
import skeleton from "@/component/skeleton/index";
import { recommendGoods } from "@/services/api";
import {
  createOrderByProductV4,
} from '@/services/userApi'
export default {
  name: "SelfEmployedGoodDetail",
  components: {
    OpenApp,
    goodsInfo,
    goodsHandle,
    AssessList,
    skeleton,
    groupDeRecommend,
  },
  data() {
    return {
      isWeChat:isWeChat(),
      skuInfo: {},
      // 占空
      flashInfo: {
        state: "",
      },
      goodsStatus: 1, // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情 7:拼团
      
      liveId: this.$route.query.liveid || 0, // 直播ID    修正 liveId
      room_id: this.$store.state.room_id || 0, // 关系ID
      robot_send: this.$store.state.robot_send || 0, // 机器人
      liveUserId: this.$route.query.liveUserId || 0, // 直播人ID
      productType: this.$route.query.parentTypeId || 0, // 产品类型
      productId: this.$route.query.goodsId || this.$route.query.product_id || 0, // 产品ID
      productSkuId:
        this.$route.query.productSkuId || this.$route.query.product_sku_id || 0, // 产品规格ID
      uid: this.$route.query.uid, // 用户ID
      goodContent: "",
      comment: {},
      goodsInfo: null, // 商品信息
      isReady: false, // 详情接口是否请求完成
      activityId: "",
      mendGoods: "",
      addressCode:this.$route.query.addressCode,
      isLife: null, // 仰生活判断
    };
  },
  mounted() {
    if(this.isWeChat){
      this.wxlogin()
    }
    // eslint-disable-next-line eqeqeq
    if (this.$route.query.status && this.$route.query.status == "7") {
      this.goodsStatus = Number(this.$route.query.status || 7);
    }
    const { codeNumber } = this.$route.query;
    localStorage.setItem(
      "shareCodeNumber",
      codeNumber && codeNumber !== "undefined" ? codeNumber : ""
    );
    this.isLife = Number(this.$route.query.isLife);
    this.$store.commit("changeInterceptUrl", window.location.href);
    // eslint-disable-next-line eqeqeq
    if (this.$route.query.status == "7") {
      this.getPtGoodsDetail();
    } else {
      this.getGoodsDetail();
    }

    // goodsStatus = Number(this.$route.query.goodsStatus || 7);
    this.dataList();
    this.skuChangeGoodsInfo();
  },
  created: function () {
    const live_id = this.$route.query.liveid || 0;
    sessionStorage.setItem("live_id", live_id);
    this.activityId = this.$route.query.activityId;
  },
  methods: {
      // 重定向地址处理函数
      urlencode(str) {
      str = (str + "").toString();
      return encodeURIComponent(str)
        .replace(/!/g, "%21")
        .replace(/'/g, "%27")
        .replace(/\(/g, "%28")
        .replace(/\)/g, "%29")
        .replace(/\*/g, "%2A")
        .replace(/%20/g, "+");
    },
    // 微信授权
    wxlogin(wxurl) {
      const _self = this;
      if (_self.$route.query.code) {
        const params = {
          code: _self.$route.query.code,
          type: 147,
        };
        getCodeByOpenid(params).then((res) => {
          if (Number(res.code) === 200) {
            const unionid = res.data.userInfo.unionid;
            _self.openId = res.data.openid;
            window.localStorage.setItem('openId',res.data.openId)
          }
        });
      } else {
        const appid = "wx05c589153c56bcfe";
        const scope = "snsapi_base";
        const state = _self.codeNumber;
        const url = _self.urlencode(window.location.href);
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          appid +
          "&redirect_uri=" +
          url +
          "&response_type=code&scope=" +
          scope +
          "&state=" +
          state +
          "#wechat_redirect";
      }
    },
        // 普通商品下单
        createOrderByProduct() {
      const _self = this
      let _orderFrom = 1
      if (_self.live_id !== '') {
        _orderFrom = 12
      }
      const { isLife, lifeOrder, timestamp, sign, PromoterUid } = this.$route.query
      if (isLife === '1') {
        _orderFrom = 15
      }
      console.log(lifeOrder)
      const params = {
        position_from: 1,
        product_id: _self.productId,
        uid: _self.uid,
        product_sku_id: _self.productSkuId,
        room_id: _self.room_id ? _self.room_id : '',
        robot_send: _self.robot_send ? _self.robot_send : '',
        codeNumber: _self.codeNumber ? _self.codeNumber : '',
        shareId: _self.codeNumber ? _self.codeNumber : '', // 分享人的id
        live_people_id: _self.live_people_id ? _self.live_people_id : '',
        live_id: _self.live_id ? _self.live_id : '',
        productType: _self.productType,
        product_num: 1,
        addressIds: _self.addressCode,
        addressType: 1,
        areaId: _self.$route.query.areaId,
        CShopId: _self.CShopId && _self.CShopId !== '' ? _self.CShopId : 0,
        cid: _self.Cid,
        couponKeyId: _self.couponKeyId,
        type: 1,
        orderFrom: _orderFrom,
        isHadBalance: 1,
        freightType: 1,
        lifeOrder: lifeOrder ? encodeURIComponent(lifeOrder) : '',
        timestamp,
        sign
      }

      
        params.deductionScore = 0
        params.deductionBean = 0
        params.totalDeduction = 0
      
      this.gotoPays(params)
    },
    skuChangeGoodsInfo() {
      const _that = this;
      const shareId = localStorage.getItem("shareCodeNumber");
      if (this.$route.query.productSkuId || this.$route.query.product_sku_id) {
        const data = {
          shareId,
          uid: window.localStorage.getItem("uid") || 0,
          productSkuId:
            this.$route.query.productSkuId || this.$route.query.product_sku_id || "",
          productType: 0,
          liveId: this.$route.query.liveid || 0,
          addressCode: this.$store.state.addressInfo.areaIds || 0,
          lon: 0,
          lat: 0,
        };
        skuChangeInfo(data).then((res) => {
          if (Number(res.code) === 200) {
            _that.$nextTick(() => {
              _that.skuInfo = res.data;
            });
          }
        });
      }
    },
    gotoPays(params) {
      // const _self = this
      const { isLife } = this.$route.query
      createOrderByProductV4({
        ...params
      }).then((res) => {
        if (Number(res.code) === 200) {
          this.$router.push({
            path: '/pay',
            query: {
              orderNo: res.ordersn,
              goodsId: this.productId,
              isLife,
              noToken:true,
              openId:this.openId
            }
          })
        } else {
          Toast(res.msg)
        }
      })
    },
    // 领完卷再次调接口
    againGet() {
      this.getGoodsDetail();
    },
    // 推荐 list
    dataList: function () {
      const data = {
        goodsId: this.$route.query.goodsId,
        uid: window.localStorage.getItem("uid"),
      };
      recommendGoods(data).then((res) => {
        if (Number(res.code) === 200) {
          this.mendGoods = res.data;
        }
      });
    },

    getPtGoodsDetail() {
      const data = {
        id: this.$route.query.id,
        uid: this.uid, // 用户ID
      };

      getGoodsDetail(data).then((res) => {
        if (Number(res.code) === 200) {
          res.data.productInfo.id = res.data.assembleInfo.id;
          res.data.productInfo.showPrice = res.data.assembleInfo.team_price;
          res.data.productInfo.priceName = "单独购";
          res.data.productInfo.goodPrice = res.data.assembleInfo.origin_price;
          res.data.productInfo.areaInfo = res.data.areaInfo;
          res.data.productInfo.assembleInfo = res.data.assembleInfo;
          res.data.productInfo.needer = res.data.assembleInfo.needer;

          this.goodContent = res.data.productInfo.goodContent;
          this.goodsInfo = res.data.productInfo;
        }
      });
    },

    getGoodsDetail() {
      const shareId = localStorage.getItem("shareCodeNumber");
      const data = {
        shareId,
        liveId: this.$route.query.liveid || this.$route.query.live_id || 0, // 直播ID
        live_id: this.$route.query.liveid || this.$route.query.live_id || 0, // 直播ID
        liveUserId: this.liveUserId, // 直播人ID
        productType: this.productType, // 产品类型
        product_id: this.productId, // 产品ID
        product_sku_id: this.productSkuId, // 产品规格ID
        room_id: this.room_id,
        robot_send: this.robot_send,
        live_people_id:
          this.$route.query.live_people_id ||
          this.$route.query.live_mid ||
          this.$store.state.live_people_id ||
          "", // 主播ID
        uid: this.uid, // 用户ID
      };

      productInfo(data).then((res) => {
        if (Number(res.code) === 200) {
          res.data.banner = res.data.banner || [];
          res.data.saleList = res.data.saleList || [];
          this.goodsInfo = res.data;
          this.isReady = true;
        } else {
          this.$router.go(-1);
        }
      });
      const conData = {
        product_id: this.productId,
      };
      productContent(conData).then((res) => {
        if (Number(res.code) === 200) {
          this.goodContent = res.data.intro;
        }
      });
      const CommentData = {
        product_id: this.productId,
        page: 9999,
        pagesize: 10,
      };
      productComment(CommentData).then((res) => {
        if (Number(res.code) === 200) {
          this.comment = res.data;
        }
      });
    },
  },
};
</script>
  
<style lang="less" scoped>
  .van-submit-bar{
    .van-button{
      width: 50%;
      background: #ff001b;
    }
  }
.crowd-tip {
  background: #fff;
  padding: 10px;
  .crowd-text{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    span:first-child{
      width: 90px;
      color: #FF0000;
    }
    span:last-child {
      overflow:hidden; text-overflow:ellipsis;white-space: nowrap;
    }
  }
  .crowd-line {
    width: 100%;
    height: 1px;
    background: #d8d8d8;
  }
}
.crowd-ratio {
  padding: 10px;
  background: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  span {
    color: #ff6565;
  }
}
.crowd-info {
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  .crowd-info-item {
    padding: 0 5px;
    border-right: 1px #d8d8d8 solid;
    flex: 1;
    &:last-child {
      border-right: unset !important;
    }
  }

  p {
    margin-bottom: 5px;
  }
}
/deep/ .ssd-module-wrap {
  div {
    width: auto;
    height: auto;
  }
}

.goods-detail {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  padding-bottom: 75px;
  overflow-x: hidden;

  .banner {
    width: 100%;
    height: 357px;
    background: #999;
  }

  .line {
    width: 100%;
    height: 7px;
    background: #f8f8f8;
  }

  .goods-detail-info {
    width: 100%;
    background: #fff;

    .tit {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 15px;
      color: #141414;
    }

    ::v-deep img {
      display: block;
      width: 100%;
    }
  }
}
</style>
  