<template>
  <div class="screen-root">
    <ul v-if="type === 'list'" class="SkeletonBox">
      <li v-for="i in 10" :key="i">
        <div class="SkeletonImg showSkeleton" />
        <div class="SkeletonTitle showSkeleton" />
        <div class="SkeletonTitle showSkeleton" style="width: 50%" />
        <div class="SkeletonTitle showSkeleton" />
      </li>
    </ul>

    <div v-if="type === 'actList'" class="SkeletonListsBox">
      <div class="SkeletonListsBanner showSkeleton" />
      <div class="SkeletonListsTitle showSkeleton" />
      <ul class="SkeletonListsUl">
        <li v-for="i in 10" :key="i">
          <div class="SkeletonImg showSkeleton" />
          <div class="SkeletonTitle showSkeleton" />
          <div class="SkeletonTitle showSkeleton" style="width: 50%" />
          <div class="SkeletonTitle showSkeleton" />
        </li>
      </ul>
    </div>

    <div v-if="type === 'index'" class="indexlist">
      <div class="indexlist-banner showSkeleton" />
      <ul class="indexlist-tabs">
        <li v-for="i in 4" :key="i" class="showSkeleton" />
      </ul>

      <ul class="indexlist-Icons">
        <li v-for="i in 10" :key="i">
          <div class="indexlist-IconsImg showSkeleton" />
          <!-- <div class="indexlist-title showSkeleton"></div> -->
        </li>
      </ul>

      <ul class="SkeletonBox">
        <li v-for="i in 10" :key="i">
          <div class="SkeletonImg showSkeleton" />
          <div class="SkeletonTitle showSkeleton" />
          <div class="SkeletonTitle showSkeleton" style="width: 50%" />
          <div class="SkeletonTitle showSkeleton" />
        </li>
      </ul>
    </div>
    <div v-if="type === 'indexList'" class="allIndexList">
      <div class="indexlist-banner showSkeleton" />
      <ul class="allIndexUl">
        <li v-for="i in 4" :key="i">
          <div class="allIndexImg showSkeleton" />
          <div class="allIndexTitleBox">
            <p class="showSkeleton" />
            <p class="showSkeleton" style="width: 40%" />
            <p class="showSkeleton" style="width: 70%" />
          </div>
        </li>
      </ul>
    </div>
    <div v-if="type === 'order'" class="orderList">
      <ul>
        <li v-for="i in 4" :key="i" class="orderli">
          <div class="order_title showSkeleton" />
          <div class="order_cen">
            <div class="showSkeleton" />
            <div>
              <p class="showSkeleton" />
              <p class="showSkeleton" />
              <p class="showSkeleton" />
            </div>
          </div>
          <div class="orderfoo showSkeleton" />
        </li>
      </ul>
    </div>

    <div v-if="type === 'dataList'" class="allIndexList">
      <ul class="allIndexUl">
        <li v-for="i in 10" :key="i">
          <div class="allIndexImg showSkeleton" />
          <div class="allIndexTitleBox">
            <p class="showSkeleton" />
            <p class="showSkeleton" style="width: 40%" />
            <p class="showSkeleton" style="width: 70%" />
          </div>
        </li>
      </ul>
    </div>

    <div v-if="type === 'detail'" class="allDetail">
      <div class="allDetailImgBox showSkeleton" />
      <div class="allDetailTitleBox">
        <div class="allDetailPrice showSkeleton" />
        <div class="allDetailTitle showSkeleton" />

        <div class="allDetailDiv showSkeleton" />

        <div class="allDetailDiv showSkeleton" style="width: 40%" />

        <div class="allDetailDiv showSkeleton" />

        <div class="allDetailDec showSkeleton" />
      </div>

      <div class="allDetaiFooterBox">
        <div class="allDetaiFooterUl">
          <div class="allDetaiIcon">
            <p class="showSkeleton" />
            <!-- <span class="showSkeleton"></span> -->
          </div>
          <div class="allDetaiIcon"><p class="showSkeleton" /></div>
          <div class="allDetaiBtn">
            <p class="showSkeleton1" />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    type: {
      type: String,
      default: 'list'
    }
  }
}
</script>
<style lang="less" scoped>
.SkeletonListsBox {
  // width: 100%;
  // height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9999;

  .SkeletonListsBanner {
    width: 100%;
    height: 400px;

  }

  .SkeletonListsTitle {
    height: 35px;
    width: 60%;
    border-radius: 17px;
    margin: 5px auto;
  }

  .SkeletonListsUl {

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 9px;
    margin: 5px 0;

    li {
      width: 32%;
      // height: 45px;
      border-radius: 6px;
      overflow: hidden;

      .SkeletonImg {
        width: 100%;
        height: 150px;
      }

      .SkeletonTitle {
        height: 15px;
        width: 100%;
        margin: 10px 0;
        border-radius: 5px;
      }
    }

  }
}

.allDetail {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background: #000;

  .allDetailImgBox {
    width: 100%;
    height: 400px;
    margin-bottom: 10px;
  }

  .allDetailTitleBox {
    padding: 10px;

    .allDetailPrice {
      width: 70px;
      height: 18px;
      border-radius: 6px;
      margin-bottom: 10px;
      // background: #000;
    }

    .allDetailTitle {
      width: 80%;
      height: 18px;
      border-radius: 6px;
      margin-bottom: 10px;
    }

    .allDetailDec {
      width: 100%;
      height: 68px;
      border-radius: 6px;
      margin-bottom: 10px;
    }

    .allDetailDiv {
      height: 18px;
      border-radius: 6px;
      margin-bottom: 10px;
    }
  }

  .allDetaiFooterBox {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    background: #fff;
    //  box-shadow: 13px 0px 0px #000;
    .allDetaiFooterUl {
      padding: 10px 0 0 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .allDetaiIcon {
        flex: 0.5;
        text-align: center;

        p {
          width: 75%;
          height: 38px;
          display: inline-block;
          // margin-bottom:5px;
          border-radius: 6px;
        }

        // span{
        //   width: 80%;
        //   height: 10px;
        //   display: inline-block;
        //     border-radius: 6px;
        // }

      }

      .allDetaiBtn {
        flex: 1;
        text-align: center;

        p {
          width: 75%;
          height: 38px;
          display: inline-block;
          //  background: red;
          border-radius: 20px;
        }
      }
    }
  }
}

.allIndexList {
}

.allIndexUl {
  padding: 9px;

  li {
    height: 100px;
    // background: #ccc;
    margin-bottom: 22px;
    border-radius: 6px;
    overflow: hidden;

    .allIndexImg {
      width: 30%;
      height: 100px;
      float: left;
      border-radius: 9px;
      // background: #000;
    }

    .allIndexTitleBox {
      float: right;
      width: 70%;
      //  background: #000;
      height: 100px;
      padding-left: 9px;
      box-sizing: border-box;

      p {
        width: 100%;
        height: 20px;
        margin-bottom: 17px;
        border-radius: 6px;
        //   background: #000;
      }
    }
  }
}

.indexlist-banner {
  width: 95%;
  height: 120px;
  margin: 0 auto;
  border-radius: 10px;
}

.indexlist-tabs {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 9px;
  margin: 5px 0;

  li {
    width: 19%;
    height: 25px;
    border-radius: 6px;
  }
}

.indexlist-Icons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 9px;

  li {
    width: 20%;
    text-align: center;
    margin-bottom: 15px;

    .indexlist-IconsImg {
      width: 50px;
      height: 50px;
      //  margin-bottom:5px;
      border-radius: 50%;
      margin: 0 auto 0px auto;
    }

    .indexlist-title {
      height: 10px;
      width: 50px;
      margin: 0 auto;
    }
  }
}

.orderList {
  width: 100%;
  padding: 12px;

  .orderli {
    width: 100%;
    padding: 10px;
    background: rgb(238, 238, 238);
    margin-bottom: 20px;
    border-radius: 6px;

    .order_title {
      width: 100%;
      height: 15px;
    }

    .order_cen {
      display: flex;
      margin-top: 10px;

      & > div:nth-child(1) {
        width: 70px;
        height: 70px;
      }

      & > div:nth-child(2) {
        width: 100%;
        margin-left: 10px;

        & > p {
          width: 60%;
          height: 15px;
          margin-bottom: 10px;
        }
      }
    }

    .orderfoo {
      width: 100%;
      height: 15px;
    }
  }
}

.SkeletonBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;

  li {
    overflow: hidden;
    width: 49%;
    margin-bottom: 20px;
    // height: 10px;
    list-style: none;
    border-radius: 9px;

    .SkeletonImg {
      width: 100%;
      height: 150px;
    }

    .SkeletonTitle {
      height: 15px;
      width: 100%;
      margin: 10px 0;
      border-radius: 5px;
    }
  }
}

.showSkeleton {
  background-image: linear-gradient(90deg,
  #e0dfdf 25%,
  #efeeee 37%,
  #e0dfdf 63%);
  background-size: 400% 100%;
  background-position: 100% 50%;
  animation: skeleton-loading 1.5s ease infinite;
}

.showSkeleton1 {
  background-image: linear-gradient(90deg,
  #e73232 25%,
  #fa5454 37%,
  #e73232 63%);
  background-size: 400% 100%;
  background-position: 100% 50%;
  animation: skeleton-loading 1.5s ease infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
