<template>
  <div v-if="mendGoods.length==null" class="group_commend_box">
    <div class="group_commend_title">
      <em><span>悦淘推荐 <i /></span></em>
    </div>
    <div class="group_commend_left">
      <div class="box_all">
        <div
          v-for="(item,index) in mendGoods"
          :key="index"
          class="box_list"
          :class="index==0 ? 'box_no' :''"
          @click="goLink(item)"
        >
          <div class="box_list_img">
            <img :src="item.goodCover" alt="">
          </div>
          <div class="box_list_span">{{ item.goodName }}</div>
          <div class="box_list_type">
            <span class="box_list_vip_price">¥{{ item.goodVipPrice }}</span>
            <span class="box_list_price">¥{{ item.goodPrice }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupDeRecommend',
  props: {
    'mendGoods': {
      type: null,
      default: null
    }
  },
  mounted() {
  },
  // props: {
  //   mendGoods: {
  //     type: Object,
  //   },
  // },
  methods: {
    goLink: function(item) {
      const obj = {
        'product_type': item.product_type, // 暂时写死 少这个这段
        'goodId': item.goodId,
        'skuId': item.skuId
      }
      this.$store.dispatch('godetail', obj)
    }

  }
}
</script>

<style lang="less" scoped>
.group_commend_box {
  margin-top: 6px;
  background: #fff;

  .group_commend_title {
    padding-top: 12px;
    box-sizing: border-box;

    em {
      position: relative;
      font-style: normal;
    }

    span {
      position: relative;
      font-size: 16px;
      font-weight: 500;
    }

    i {
      width: 100%;
      height: 8px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(255, 168, 168, .4));
      position: absolute;
      right: 1px;
      bottom: 2px;
    }

    span:before {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: -webkit-linear-gradient(#f5efdf, #ff4700);
      background: -o-linear-gradient(#f5efdf, #ff4700);
      background: -moz-linear-gradient(#f5efdf, #ff4700);
      background: linear-gradient(#f5efdf, #ff4700);
      position: absolute;
      left: -20px;
      top: 2px;
    }

    span::after {
      content: "";
      width: 10px;
      height: 10px;
      background: -webkit-linear-gradient(#f5efdf, #ff4700);
      background: -o-linear-gradient(#f5efdf, #ff4700);
      background: -moz-linear-gradient(#f5efdf, #ff4700);
      background: linear-gradient(#f5efdf, #ff4700);
      border-radius: 50%;
      position: absolute;
      right: -16px;
      top: 6px;
    }

    em::after {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: -webkit-linear-gradient(#f5efdf, #d68a00);
      background: -o-linear-gradient(#f5efdf, #d68a00);
      background: -moz-linear-gradient(#f5efdf, #d68a00);
      background: linear-gradient(#f5efdf, #d68a00);
      position: absolute;
      left: -30px;
    }
  }

  .group_commend_left {
    overflow: hidden;
    height: 210px;
    margin-top: 12px;
  }

  .box_all {
    background: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    //white-space: nowrap;
    overflow-y: auto;
  }

  .box_list {
    width: 120px;
    margin-left: 12px;
    background: #fff;
    display: inline-block;
  }

  .box_no {
    display: block;
  }

  .box_list_img {
    width: 120px;
    height: 120px;
    border-radius: 6px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .box_list_span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 18px;
    margin-top: 8px;
  }

  .box_list_type {
    text-align: left;
    margin-top: 8px;

    .box_list_vip_price {
      color: red;
      font-size: 14px;
      margin-top: 2px;
    }

    .box_list_price {
      color: #999;
      text-decoration: line-through;
      font-size: 10px;
    }
  }
}

</style>
